<template>
    <MainContainer>
        <div class="data-privacy-notice">
            <b-row>
                <b-col cols="12" lg="6" offset-lg="3">
                    <header class="mt-4 mb-4">
                        <b-row>
                            <b-col cols="12" lg="8">
                                <img
                                    alt="LV VHS Logo"
                                    class="logo"
                                    src="@/assets/images/logo_width.png"
                                    width="350"
                                />
                            </b-col>
                        </b-row>
                    </header>
                    <section>
                        <article>
                            <h1>Datenschutzbestimmungen</h1>
                            <p>
                                Der Schutz Ihrer Privatsphäre ist uns wichtig. Mit dieser Datenschutzerklärung möchten
                                wir Sie über die Verarbeitung Ihrer personenbezogenen Daten bei der Nutzung unserer
                                Formulare informieren.
                            </p>
                            <p>
                                Die Verarbeitung Ihrer personenbezogenen Daten erfolgt insbesondere auf Grundlage der
                                Datenschutz-Grundverordnung (DS-GVO) sowie der einschlägigen Gesetze zur Regelung des
                                Datenschutzes.
                            </p>
                        </article>
                        <article>
                            <h2>
                                Verantwortliche/r und Datenschutzbeauftragte/r
                            </h2>
                            <p>
                                Verantwortlicher im Sinne des Art. 4 Abs. 7 DS-GVO (Datenschutz-Grundverordnung) ist:
                            </p>
                            <p class="ml-4">
                                Landesverband der Volkshochschulen von NRW&nbsp;e.&nbsp;V.<br />
                                Bismarckstr. 98<br />
                                40210 Düsseldorf<br />
                                Telefon: +49 (0) 211 542 141 0<br />
                                E-Mail:
                                <a href="mailto:service@vhs-nrw.de"
                                    >service(at)vhs-nrw.de</a
                                >
                            </p>
                            <p>
                                Sollten Sie Fragen zum Datenschutz haben, richten Sie diese bitte an die
                                Datenschutzbeauftragte des Landesverbandes der Volkshochschulen von NRW&nbsp;e.&nbsp;V.:
                            </p>
                            <p>
                                Frau Alexandra Horn, E-Mail-Adresse
                                <a href="mailto:datenschutz@vhs-nrw.de"
                                    >datenschutz[at]vhs-nrw.de</a
                                >
                            </p>
                        </article>
                        <article>
                            <h2>Verarbeitung von personenbezogenen Daten</h2>
                            <p>
                                Die im Rahmen der Nutzung unserer Formulare abgefragten Daten werden zur Durchführung
                                unserer Angebote verwendet. Hierbei handelt es sich teilweise um Pflichtangaben, ohne
                                deren Angabe keine Nutzung unseres Angebotes möglich ist. Diese sind mit Sternchen
                                gekennzeichnet. Alle Angaben darüber hinaus sind freiwillig.
                            </p>
                            <p>
                                Im Wesentlichen handelt es sich dabei um folgende Datenkategorien: Name, Vorname,
                                Adresse, Telefonnummer, E-Mailadresse, Geburtsdatum, Geschlecht.
                            </p>
                            <p>
                                Die Abfrage Ihrer Telefonnummer und Ihrer E-Mailadresse erfolgt in unserem berechtigten
                                Interesse, Sie im Rahmen unseres Angebotes unmittelbar kontaktieren zu können. Wenn Sie
                                uns diese Daten nicht zur Verfügung stellen, können wir Sie ggf. nicht rechtzeitig
                                erreichen bzw. Ihnen möglicherweise keine weiterführenden Informationen (wie z.&nbsp;B.
                                Zugangsdaten zu Online-Angeboten) zukommen lassen.
                            </p>
                            <p>
                                Sämtliche von Ihnen bereitgestellten Daten werden elektronisch gespeichert. Die
                                hierdurch entstehenden Datenbanken und Anwendungen können durch von uns beauftragte
                                IT-Dienstleister betreut werden. Zur Sicherstellung eines geeigneten Datenschutzniveaus
                                haben wir mit diesen Dienstleistern Verträge gemäß Art. 28 DS-GVO geschlossen.
                            </p>
                            <p>
                                Sofern Sie an einer Umfrage teilnehmen, oder Bewertungen zu unseren Angeboten abgeben,
                                erfolgt die Verarbeitung Ihrer personenbezogenen Daten zu Auswertungszwecken, mit dem
                                Ziel unsere Angebote zu optimieren und Ihre Präferenzen zu berücksichtigen.
                            </p>
                        </article>
                        <article>
                            <h2>Rechtsgrundlagen der Verarbeitung</h2>
                            <ol class="ol-list">
                                <li>
                                    Soweit Sie uns für die Verarbeitung Ihrer personenbezogenen Daten eine Einwilligung
                                    erteilt haben, stellte diese die Rechtsgrundlage für die Verarbeitung dar (Art. 6
                                    Abs. 1 Buchst. a DSGVO).
                                </li>
                                <li>
                                    Für eine Verarbeitung personenbezogener Daten für die Zwecke der Anbahnung oder der
                                    Erfüllung eines Vertrages mit Ihnen ist Art. 6 Abs. 1 Buchst. b DSGVO die
                                    Rechtsgrundlage.
                                </li>
                                <li>
                                    Soweit die Verarbeitung Ihrer personenbezogenen Daten zur Erfüllung unserer
                                    rechtlichen Verpflichtungen (z. B. zur Aufbewahrung von Daten) erforderlich ist,
                                    sind wir dazu gemäß Art. 6 Abs. 1 Buchst. c DSGVO befugt.
                                </li>
                                <li>
                                    Außerdem verarbeiten wir personenbezogene Daten zu Zwecken der Wahrnehmung unserer
                                    berechtigten Interessen sowie berechtigter Interessen Dritter gemäß Art. 6 Abs. 1
                                    Buchst. f DSGVO.
                                </li>
                            </ol>
                        </article>
                        <article>
                            <h2>Datenweitergabe an Dritte</h2>
                            <p>
                                Wir geben Ihre personenbezogenen Daten nur dann an Dritte weiter, sofern hierzu eine
                                gesetzliche Verpflichtung besteht oder die Weitergabe zur Vertragserfüllung notwendig
                                ist.
                            </p>
                            <ul>
                                <li>
                                    Weitergabe an das Bundesamt für Migration und Flüchtlinge (BAMF) und weiterer
                                    Behörden wie zum Beispiel die Bezirksregierung Düsseldorf. Ihren Namen (Nachname,
                                    Vorname), Geburtsdatum, Herkunftsland sowie Geburtsort können an das Bundesamt für
                                    Migration und Flüchtlinge und weitere Behörden zur Vorbereitung, Durchführung einer
                                    Schulungsmaßnahme und zum Nachweis einer Schulungsmaßnahme für Fördermittelgeber
                                    weitergegeben werden. Diese Übermittlungen dienen der Vertragserfüllung bzw. der
                                    Erfüllung einer Rechtspflicht.
                                </li>
                                <li>
                                    Weitergabe an externe Prüfungsinstitute<br />
                                    Für die Teilnahme an zertifizierten Prüfungen, Lehrgängen und Abschlüssen (z. B.
                                    Cambridge-, Goethe- oder telc-Sprachprüfungen, Xpert-Prüfungen,
                                    Zusatzqualifizierungen, Erwachsenenpädagogische Qualifikation,
                                    Prüfer/innenschulungen, Schulabschlüssen) erfolgt eine Weitergabe Ihrer
                                    personenbezogenen Daten an die jeweiligen Prüfungsinstitute. Diese Übermittlungen
                                    dienen der Vertragserfüllung.
                                </li>
                                <li>
                                    Weitergabe an Inkassodienstleister<br />
                                    Befinden Sie sich mit einer Zahlung in Verzug, behalten wir uns vor, Ihre Daten
                                    (Name, Anschrift, Geburtsjahr) an einen Inkassodienstleister zur Durchsetzung der
                                    Forderung im Rahmen des Art. 6 Abs. 1 lit. f zur Durchsetzung unserer berechtigten
                                    Interessen weiterzuleiten.
                                </li>
                            </ul>
                        </article>
                        <article>
                            <h2>Speicherdauer und Löschung</h2>
                            <p>
                                Ihre personenbezogenen Daten werden gelöscht, sobald der Zweck, zu dem wir die Daten
                                verarbeitet haben, entfällt. Über diesen Zeitpunkt hinaus findet eine Speicherung nur
                                statt, wenn dies gemäß den Gesetzen, Verordnungen oder sonstigen Rechtsvorschriften der
                                Europäischen Union oder eines Mitgliedstaates der Europäischen Union, denen wir
                                unterliegen, erforderlich ist.
                            </p>
                        </article>
                        <article>
                            <h2>Betroffenenrechte</h2>
                            <p>
                                Sie haben folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
                            </p>
                            <ul>
                                <li>
                                    Recht auf Auskunft, Art. 15 DSG-VO: Sie haben das Recht von dem Verantwortlichen
                                    über die Verarbeitung Ihrer personenbezogenen Daten Auskunft zu verlangen.
                                </li>
                                <li>
                                    Recht auf Berichtigung, Art. 16 DS-GVO: Sie haben ein Recht auf Berichtigung und /
                                    oder Vervollständigung Ihrer personenbezogenen Daten, sofern diese beim
                                    Verantwortlichen unrichtig oder unvollständig sind. Der Verantwortliche hat die
                                    Berichtigung unverzüglich vorzunehmen.
                                </li>
                                <li>
                                    Recht auf Löschung („Recht auf Vergessenwerden“), Art. 17 DS-GVO: Sie können
                                    verlangen, dass Ihre personenbezogenen Daten unverzüglich gelöscht werden. Der/Die
                                    Verantwortliche ist unter Umständen verpflichtet, diese Daten unverzüglich zu
                                    löschen, sofern einer der folgenden Gründe zutrifft:
                                    <ul>
                                        <li>
                                            Ihre personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder
                                            auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                                        </li>
                                        <li>
                                            Sie widerrufen die Einwilligung zur Verarbeitung Ihrer personenbezogenen
                                            Daten und es fehlt an einer anderweitigen Rechtsgrundlage hierfür.
                                        </li>
                                        <li>
                                            Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig
                                            verarbeitet.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Recht auf Einschränkung der Verarbeitung, Art. 18 DS-GVO Unter den folgenden
                                    Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden
                                    personenbezogenen Daten verlangen:
                                    <ul>
                                        <li>
                                            Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine
                                            Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit
                                            der personenbezogenen Daten zu überprüfen.
                                        </li>
                                        <li>
                                            die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen
                                            Daten nicht wünschen, sondern stattdessen die Einschränkung der Nutzung der
                                            personenbezogenen Daten verlangen.
                                        </li>
                                        <li>
                                            Der Verantwortliche benötigt Ihre personenbezogenen Daten für die Zwecke der
                                            Verarbeitung nicht länger – Sie jedoch benötigen diese zur Geltendmachung,
                                            Ausübung oder Verteidigung von Rechtsansprüchen.
                                        </li>
                                        <li>
                                            Sie haben Widerspruch gegen die Verarbeitung gemäß Art. 21 I DS-GVO
                                            eingelegt und es steht noch nicht fest, ob die berechtigten Gründe des
                                            Verantwortlichen gegenüber Ihren Gründen überwiegen. Wurde die Verarbeitung
                                            der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese
                                            Daten – von ihrer Speicherung abgesehen – nur noch unter bestimmten
                                            Voraussetzungen verarbeitet werden.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Recht auf Datenübertragbarkeit, Art. 20 DS-GVO Sie haben das Recht, die Sie
                                    betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt
                                    haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
                                </li>
                                <li>
                                    Recht auf Widerspruch gegen die Verarbeitung, Art. 21 DS-GVO Sie haben das Recht,
                                    aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
                                    Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
                                    Abs. 1 lit. e) oder f) DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch für
                                    ein auf diese Bestimmungen gestütztes Profiling. Der Verantwortliche verarbeitet
                                    Ihre personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende
                                    schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Rechte und Freiheiten
                                    überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                                    Verteidigung von Rechtsansprüchen.
                                </li>
                                <li>
                                    Recht auf Widerruf Ihrer Einwilligungserklärung, Art. 7 III DS-GVO Sie haben das
                                    Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen –
                                    durch diesen Widerruf wird die Rechtmäßigkeit der vorigen Verarbeitung nicht
                                    berührt.
                                </li>
                                <li>
                                    Beschwerderecht bei der Aufsichtsbehörde Gemäß Artikel 77 Abs. 1 DS-GVO haben Sie
                                    das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass
                                    die Verarbeitung Ihrer personenbezogenen Daten gegen die Regelungen der DSGVO
                                    verstößt.
                                </li>
                            </ul>
                            <p>
                                Alle Informationswünsche, Auskunftsanfragen oder sonstige Begehren richten Sie bitte an
                                unsere Datenschutzbeauftragte.
                            </p>
                        </article>
                        <footer class="small mt-4 border-top pt-4 mb-4">
                            <b-row>
                                <b-col cols="4">
                                    Landesverband der Volkshochschulen von NRW&nbsp;e.&nbsp;V.<br />
                                    Sitz: Düsseldorf<br />
                                    Vorstand:<br />
                                    <span class="ml-3">Celia Sokolowsky</span><br />
                                    <span class="ml-3">Jens Kemner</span>
                                </b-col>
                                <b-col cols="4">
                                    Registergericht: Amtsgericht Düsseldorf<br />
                                    Registernummer: VR 10799<br />
                                    Steuernummer: 133/5908/2561
                                </b-col>
                                <b-col cols="4">
                                    Kontoinhaber: Landesverband der Volkshochschulen von NRW&nbsp;e.&nbsp;V.<br />
                                    Stadtsparkasse Düsseldorf<br />
                                    IBAN: DE02&nbsp;3005&nbsp;0110&nbsp;1005&nbsp;1926&nbsp;51<br />
                                    BIC: DUSSDEDDXXX<br />
                                    <img
                                        alt="LV VHS Logo"
                                        class="logo"
                                        src="@/assets/images/footer_logo.png"
                                        width="200"
                                    />
                                </b-col>
                            </b-row>
                        </footer>
                    </section>
                </b-col>
            </b-row>
        </div>
    </MainContainer>
</template>

<script>
// @ is an alias to /src
import MainContainer from "@/components/MainContainer/MainContainer";

import "./DataPrivacyNotice.scss";

export default {
    name: "DataPrivacyNotice",
    components: {
        MainContainer
    }
};
</script>
